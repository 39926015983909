<template>
  <div class="legal__page">
    <div class="legal-header">
      <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
    </div>
    <div class="legal-container">
      <Legal />
    </div>
    <div class="subscribe-bar">
      <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
    </div>
    <div class="legal__footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { Header, Legal, Footer, SubscribeBar } from '@/components'

export default {
  components: {
    Legal,
    Header,
    Footer,
    SubscribeBar
  },
  data() {
    return {
      showSusbscribeBar: true
    }
  },
  methods: {
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
.legal__page {
  position: relative;
  .legal-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $primary-white;
  }
  .legal-container {
    margin-top: 80px;
  }
}
</style>
